import { Button } from "react-bootstrap"
import { useMsal } from "@azure/msal-react"
import { loginRequest } from "../authConfig";
import windows from "../Images/windows.png"
import logo from "../Images/logo.png"
const UnauthenticatedHome = () => {
    const {instance} = useMsal()
    const handleLogin = (loginType: any) => {
        if (loginType === "popup") {
            instance.loginPopup(loginRequest).catch(e => {
                console.log(e);
            });
        } else if (loginType === "redirect") {
            instance.loginRedirect(loginRequest).catch(e => {
                console.log(e);
            });
        }
    }
    return (
        <div className="unAuth">
            <div className="unAuth-image"></div>
            <div className="unAuth-login">
                <img className="unAuth-login__logo" src={logo} />
                <Button className="unAuth-login__button" onClick={() => handleLogin("redirect")}>Continue to IT Support</Button>
            </div>
        </div>
    )
}

export default UnauthenticatedHome