import { Asset, User, graphAPIData, ticket } from "../Pages/AuthenticatedHome"
import wave from "../Images/wave.png"
import { Button, Form } from "react-bootstrap"
import { FaX } from "react-icons/fa6";
import { useEffect, useState } from "react";
import { allUsers } from "../TempData/TempData";
import { Typeahead } from "react-bootstrap-typeahead";

interface Props {
    children: React.ReactNode
    asset?: Asset
    user: graphAPIData
    admins: User[]
    viewAsset: boolean
    create: boolean
    closeAsset: () => void
    allData: Asset[]
    saveAsset: (asset: Asset) => void
}

const AssetContainer = ({ allData, children, create, asset, user, admins, closeAsset, viewAsset, saveAsset }: Props) => {
    const [assigned, setAssigned] = useState<User>()
    const [assetName, setAssetName] = useState<string>("")
    const [mac, setMac] = useState<string>("")
    const [brand, setBrand] = useState<string>("")
    const [source, setSource] = useState<string>("")
    const [price, setPrice] = useState<string>("")
    const [status, setStatus] = useState<string>("")
    const [category, setCategory] = useState<string>("")
    const [company, setCompany] = useState<string>("")
    const checkState = () => {
        if (create === false && viewAsset === false) {
            return true
        }
        return false
    }

    useEffect(() => {
        if (viewAsset && asset) {
            setAssetName(asset?.productTitle)
            setMac(asset.serialNumber)
            setBrand(asset.brand)
            setSource(asset.source)
            setPrice(asset.price)
            setStatus(asset.status)
            setCategory(asset.category)
            setAssigned(asset.assigned.user)
            setCompany(asset.company || "")
        }
    }, [asset])

    const submit = () => {
        let temp: Asset = {
            assigned: {
                ticket: "",
                user: {
                    email: "",
                    id: "",
                    name: ""
                }
            },
            brand: "",
            category: "",
            history: [],
            id: "",
            imageId: "",
            imei: "",
            mobile: "",
            price: "",
            productTitle: "",
            serialNumber: "",
            source: "",
            status: "",
            company: ""
        };
        temp.id = "ESL-IT-" + (allData.length + 1).toString()
        if (viewAsset && asset) {
            temp = asset
        }
        temp.assigned = { ticket: "", user: assigned || { email: "", id: "", name: "" } }
        temp.productTitle = assetName
        temp.serialNumber = mac
        temp.brand = brand
        temp.source = source
        temp.price = price
        temp.status = status
        temp.category = category
        temp.company = company
        saveAsset(temp)
        setAssetName("")
        setMac("")
        setBrand("")
        setSource("")
        setPrice("")
        setStatus("")
        setCategory("")
        setAssigned(undefined)
        setCompany("")
    }

    useEffect(() => {
        if (create === true) {
            setAssigned({ email: "", id: "", name: "" })
            setSource("")
            setPrice("")
            setAssetName("")
            setMac("")
            setBrand("")
            setCategory("")
            setStatus("Checked in")
            setCompany("")
        }
    }, [create])

    return (
        <div className="containerES" style={{ overflowX: "hidden" }}>
            <div className={`containerES-children ${checkState() && "w-100"}`}>
                {children}
            </div>
            {viewAsset && asset &&
                <div className="containerES-ticket">
                    <img className="containerES-ticket__wave" src={wave} />
                    <div className="containerES-ticket-content">
                        <div className="d-flex flex-row justify-content-between align-items-center">
                            <p className="containerES-ticket-content__id">#{asset?.id}</p>
                            <FaX onClick={closeAsset} className="containerES-ticket-content__close" color="white" size={24} />
                        </div>
                        <Form.Group className="mb-2">
                            <p className="containerES-ticket-content__label">Asset name:</p>
                            <Form.Control value={assetName} onChange={(e) => setAssetName(e.currentTarget.value)} placeholder="Enter asset name..." />
                        </Form.Group>
                        <div className="d-flex flex-row">
                            <div className="w-50">
                                <Form.Group className="mb-2 mr1">
                                    <p className="containerES-ticket-content__label">Status:</p>
                                    <Form.Select value={status} onChange={(e) => setStatus(e.currentTarget.value)}>
                                        <option value="">Select a status...</option>
                                        <option value="Broken">Broken</option>
                                        <option value="Checked in">Checked in</option>
                                        <option value="Checked out">Checked out</option>
                                        <option value="Discontinued">Discontinued</option>
                                    </Form.Select>
                                </Form.Group>
                            </div>
                            <div className="w-50">
                                <Form.Group className="mb-2" >
                                    <p className="containerES-ticket-content__label">Assigned:</p>
                                    {assigned?.name !== "" ?
                                        <p className="font-cursor" onClick={() => setAssigned({ email: "", id: "", name: "" })}>{assigned?.name}</p>
                                        :
                                        <Form.Group className="mb-3" >
                                            <Typeahead
                                                placeholder='Search for user...'
                                                labelKey="displayName"
                                                onChange={(selected) => {
                                                    //@ts-ignore
                                                    setAssigned({ email: selected[0].mail, name: selected[0].displayName, id: selected[0].id })
                                                }}
                                                options={allUsers}
                                            />
                                        </Form.Group>
                                    }
                                </Form.Group>
                            </div>
                        </div>
                        <Form.Group className="mb-2" >
                            <p className="containerES-ticket-content__label">MAC/Serial number:</p>
                            <Form.Control value={mac} onChange={(e) => setMac(e.currentTarget.value)} placeholder="Enter MAC/Serial number..." />
                        </Form.Group>
                        <div className="d-flex flex-row">
                            <div className="w-50 mr1">
                                <Form.Group className="mb-2" >
                                    <p className="containerES-ticket-content__label">Category:</p>
                                    <Form.Select value={category} onChange={(e) => setCategory(e.currentTarget.value)}>
                                        <option value="">Select asset category...</option>
                                        <option value="Case">Case</option>
                                        <option value="Chair">Chair</option>
                                        <option value="Charger">Charger</option>
                                        <option value="Desk phone">Desk phone</option>
                                        <option value="Desktop">Desktop</option>
                                        <option value="Keyboard">Keyboard</option>
                                        <option value="Laptop">Laptop</option>
                                        <option value="Laptop repair">Laptop repair</option>
                                        <option value="Mobile phone">Mobile phone</option>
                                        <option value="Monitor">Monitor</option>
                                        <option value="Monitor arms">Monitor arms</option>
                                        <option value="Mouse">Mouse</option>
                                        <option value="Printer">Printer</option>
                                        <option value="Splitter">Splitter</option>
                                        <option value="Tablet">Tablet</option>
                                        <option value="Wireless keyboard & mouse">Wireless keyboard & mouse</option>
                                    </Form.Select>
                                </Form.Group>
                            </div>
                            <div className="w-50">
                                <Form.Group className="mb-2" >
                                    <p className="containerES-ticket-content__label">Brand</p>
                                    <Form.Control value={brand} onChange={(e) => setBrand(e.currentTarget.value)} placeholder="Enter brand..." />
                                </Form.Group>
                            </div>
                        </div>
                        <div className="d-flex flex-row">
                            <div className="w-50 mr1">
                                <Form.Group className="mb-3" >
                                    <p className="containerES-ticket-content__label">Source:</p>
                                    <Form.Control value={source} onChange={(e) => setSource(e.currentTarget.value)} placeholder="Enter source..." />
                                </Form.Group>
                            </div>
                            <div className="w-50">
                                <Form.Group className="mb-3" >
                                    <p className="containerES-ticket-content__label">Price:</p>
                                    <Form.Control value={price} onChange={(e) => setPrice(e.currentTarget.value)} placeholder="Enter price..." />
                                </Form.Group>
                            </div>
                        </div>
                        <div>
                            <Form.Group className="mb-2">
                                <p className="containerES-ticket-content__label">Company:</p>
                                <Form.Select value={company} onChange={(e) => setCompany(e.currentTarget.value)} aria-label="Default select example">
                                    <option value="">Select company...</option>
                                    <option value="Eurosafe">Eurosafe</option>
                                    <option value="Eurosafe Compliance">Eurosafe Compliance</option>
                                    <option value="Eurosafe Contracting">Eurosafe Contracting</option>
                                    <option value="Eurosafe Training & PPE">Eurosafe Training & PPE</option>
                                </Form.Select>
                            </Form.Group>
                        </div>
                        <div className="d-flex justify-content-end">
                            <Button onClick={() => {
                                submit()
                                closeAsset()
                            }} className="buttonES">Save asset</Button>
                        </div>
                    </div>
                </div>
            }
            {create &&
                <div className="containerES-ticket">
                    <img className="containerES-ticket__wave" src={wave} />
                    <div className="containerES-ticket-content">
                        <div className="d-flex flex-row justify-content-between align-items-center">
                            <p className="containerES-ticket-content__id">Create new asset</p>
                            <FaX onClick={closeAsset} className="containerES-ticket-content__close" color="white" size={24} />
                        </div>
                        <Form.Group className="mb-2">
                            <p className="containerES-ticket-content__label">Asset ID:</p>
                            <Form.Control value={allData.length + 1} disabled={true} />
                        </Form.Group>
                        <Form.Group className="mb-2">
                            <p className="containerES-ticket-content__label">Asset name:</p>
                            <Form.Control value={assetName} onChange={(e) => setAssetName(e.currentTarget.value)} placeholder="Enter asset name..." />
                        </Form.Group>
                        <Form.Group className="mb-2">
                            <p className="containerES-ticket-content__label">Category:</p>
                            <Form.Select value={category} onChange={(e) => setCategory(e.currentTarget.value)} aria-label="Default select example">
                                <option value="">Select asset category...</option>
                                <option value="Case">Case</option>
                                <option value="Chair">Chair</option>
                                <option value="Charger">Charger</option>
                                <option value="Desk phone">Desk phone</option>
                                <option value="Desktop">Desktop</option>
                                <option value="Keyboard">Keyboard</option>
                                <option value="Laptop">Laptop</option>
                                <option value="Laptop repair">Laptop repair</option>
                                <option value="Mobile phone">Mobile phone</option>
                                <option value="Monitor">Monitor</option>
                                <option value="Monitor arms">Monitor arms</option>
                                <option value="Mouse">Mouse</option>
                                <option value="Printer">Printer</option>
                                <option value="Software">Software</option>
                                <option value="Splitter">Splitter</option>
                                <option value="Tablet">Tablet</option>
                                <option value="Wireless keyboard & mouse">Wireless keyboard & mouse</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-2">
                            <p className="containerES-ticket-content__label">MAC/Serial number:</p>
                            <Form.Control value={mac} onChange={(e) => setMac(e.currentTarget.value)} placeholder="Enter MAC/Serial number..." />
                        </Form.Group>
                        <Form.Group className="mb-2">
                            <p className="containerES-ticket-content__label">Brand name:</p>
                            <Form.Control value={brand} onChange={(e) => setBrand(e.currentTarget.value)} placeholder="Enter brand name..." />
                        </Form.Group>
                        <Form.Group className="mb-2">
                            <p className="containerES-ticket-content__label">Company:</p>
                            <Form.Select value={company} onChange={(e) => setCompany(e.currentTarget.value)} aria-label="Default select example">
                                <option value="">Select company...</option>
                                <option value="Eurosafe">Eurosafe</option>
                                <option value="Eurosafe Compliance">Eurosafe Compliance</option>
                                <option value="Eurosafe Contracting">Eurosafe Contracting</option>
                                <option value="Eurosafe Training & PPE">Eurosafe Training & PPE</option>
                            </Form.Select>
                        </Form.Group>
                        <div className="d-flex justify-content-end">
                            <Button onClick={() => {
                                submit()
                                closeAsset()
                            }} className="buttonES">Create asset</Button>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default AssetContainer