import React from "react";
import { Droppable } from "react-beautiful-dnd";
import ListItem from "./ListItem";
import { ProjectTicket } from "../Pages/SubPages/Developer";
import { FaCirclePlus } from "react-icons/fa6";

interface ComponentType {
  prefix: string;
  elements: ProjectTicket[];
  addTicket: (prefix: string) => void
  onTicketClick: (ticket: ProjectTicket) => void
};

const DraggableElement = ({ prefix, elements, addTicket, onTicketClick }: ComponentType) => {
  return (
    <div className="droppable">
      <div className="d-flex flex-row justify-content-between align-items-center prefix">
        <h5 className="m-0 font-bold">{prefix}</h5>
        <a onClick={() => addTicket(prefix)} className="m-0 prefix__add"><FaCirclePlus color="white" size={18} />Add ticket</a>
      </div>

        <Droppable droppableId={`${prefix}`}>
          {(provided) => (
            <div className="dropContainer" {...provided.droppableProps} ref={provided.innerRef}>
              {elements.map((item: ProjectTicket, index: number) => (
                <ListItem onClick={() => onTicketClick(item)} prefix={prefix} key={item.id} item={item} index={index} />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>

    </div>
  );
};

export default DraggableElement;
