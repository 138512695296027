import { DataGrid, GridColDef, GridRowSelectionModel, GridRowsProp } from "@mui/x-data-grid";
import { KnowledgebaseItem, User, graphAPIData, ticket } from "../AuthenticatedHome";
import Status from "../../Components/Status";
import Container from "../../Components/Container";
import { useEffect, useState } from "react";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { FaFileCirclePlus } from "react-icons/fa6";
import KnowledgeContainer from "../../Components/KnowledgeContainer";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import { Timestamp } from "firebase/firestore";
import moment from "moment";
import React, { PureComponent } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Project } from "./Developer";

interface Props {
    page: string
    allData: ticket[]
    user: graphAPIData
    onAssignedChange: (value: User, ticket: ticket) => void
    admins: User[]
    onStatusPriorityChange: (type: string, value: string, ticket: ticket) => void
    addNewComment: (ticket: ticket) => void
    onSubChange: (ticket: ticket) => void
    knowledgebase: KnowledgebaseItem[]
    newKnowledge: (item: KnowledgebaseItem) => void
    projects: Project[];
}

const Dashboard = ({ page, knowledgebase, allData, projects, newKnowledge, user, admins, onStatusPriorityChange, onAssignedChange, addNewComment, onSubChange }: Props) => {
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
    const [selectedTicket, setSelectedTicket] = useState<ticket>()
    const [viewing, setViewing] = useState<boolean>(false)
    const [contextMenu, setContextMenu] = useState<{
        mouseX: number;
        mouseY: number;
    } | null>(null);
    const [create, setCreate] = useState<boolean>(false)
    const handleContextMenu = (event: React.MouseEvent) => {
        event.preventDefault();
        setContextMenu(
            contextMenu === null
                ? {
                    mouseX: event.clientX + 2,
                    mouseY: event.clientY - 6,
                }
                : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                // Other native context menus might behave different.
                // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                null,
        );
    };

    const handleClose = () => {
        setContextMenu(null);
    };

    const getScheduled = (sched: Timestamp) => {
        console.log(sched)
        if(sched && sched instanceof Timestamp) {
        return moment(sched.toDate()).format("DD/MM/YYYY")
        }
        return ""
    }

    
    function formatDate(dateString: string) {
        // Check if the date is in "Tue Sep 10 2024 13:03:45 GMT+0100" format
        if (moment(dateString, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ', true).isValid()) {
            return moment(dateString).format('DD/MM/YYYY');
        }

        // Check if the date is in "10/09/24" format
        if (moment(dateString, 'DD/MM/YYYY', true).isValid()) {
            return moment(dateString, 'DD/MM/YYYY').format('DD/MM/YYYY');
        }

        // Fallback for unrecognized formats
        return 'Invalid Date';
    }

    const getRows = () => {
        let rows: any = []
        if (page === "Assign Admin") {
            admins.map((admin) => {
                rows.push({
                    id: admin.id,
                    name: admin.name,
                    email: admin.email
                })
            })
        } else {
            allData.map((ticket: ticket) => {
                let t = ""
                if (ticket.scheduledDate) {
                    //@ts-ignore
                    const temp: Timestamp = ticket.scheduledDate
                    t = getScheduled(temp)
                }
                if (page === "Assigned Tickets" && (ticket.assigned?.id === user.id && ticket.status !== "Completed" && ticket.status !== "No Response"  && ticket.status !== "Not Applicable")) {
                    rows.push({
                        id: "#" + ticket.ticketId,
                        summary: ticket.subject,
                        device: ticket.device || "Not assigned",
                        type: ticket.type || "Not assigned",
                        assigned: ticket.assigned?.name,
                        raisedBy: ticket.raisedBy.name,
                        status: <Status className="w-75" text={ticket.status} />,
                        priority: <Status className="w-75" text={ticket.priority} />,
                        scheduled: t,
                        raised: formatDate(ticket.dateRaised)
                    })
                }
                if (page === "High Priority" && ticket.priority === "High priority") {
                    rows.push({
                        id: "#" + ticket.ticketId,
                        summary: ticket.subject,
                        device: ticket.device || "Not assigned",
                        type: ticket.type || "Not assigned",
                        assigned: ticket.assigned?.name,
                        raisedBy: ticket.raisedBy.name,
                        status: <Status className="w-75" text={ticket.status} />,
                        priority: <Status className="w-75" text={ticket.priority} />,
                        scheduled: t,
                        raised: formatDate(ticket.dateRaised)

                    })
                }
                if (page === "Completed" && (ticket.status === "Completed" || ticket.status === "No Response" || ticket.status === "Not Applicable")) {
                    rows.push({
                        id: "#" + ticket.ticketId,
                        summary: ticket.subject,
                        device: ticket.device || "Not assigned",
                        type: ticket.type || "Not assigned",
                        assigned: ticket.assigned?.name,
                        raisedBy: ticket.raisedBy.name,
                        status: <Status className="w-75" text={ticket.status} />,
                        priority: <Status className="w-75" text={ticket.priority} />,
                        scheduled: t,
                        raised: formatDate(ticket.dateRaised)

                    })
                }
                if (page === "1101 Notified" && ticket.status === "MCI notified") {
                    rows.push({
                        id: "#" + ticket.ticketId,
                        summary: ticket.subject,
                        device: ticket.device || "Not assigned",
                        type: ticket.type || "Not assigned",
                        assigned: ticket.assigned?.name,
                        raisedBy: ticket.raisedBy.name,
                        status: <Status className="w-75" text={ticket.status} />,
                        priority: <Status className="w-75" text={ticket.priority} />,
                        scheduled: t,
                        raised: formatDate(ticket.dateRaised)

                    })
                }
                if (page === "Knowledgebase" && ticket.status === "Completed" && ticket.ticketId > 101022) {
                    let res = "Awaiting action"
                    knowledgebase.map((item) => {
                        if (item.ticketId === ticket.ticketId && convertToRaw(EditorState.createWithContent(convertFromRaw(JSON.parse(item.text))).getCurrentContent()).blocks[0].text !== "") {
                            res = "Completed"
                        }
                    })
                    rows.push({
                        id: "#" + ticket.ticketId,
                        summary: ticket.subject,
                        device: ticket.device || "Not assigned",
                        type: ticket.type || "Not assigned",
                        status: <Status className="w-75" text={ticket.status} />,
                        resolution: <Status className="w-75" text={res} />,

                    })
                }
            })
        }
        let rowsCorrect: GridRowsProp = rows
        return rowsCorrect
    }

    const getColumns = () => {
        let columns: GridColDef[] = []
        if (page === "High Priority" || page === "Completed" || page === "1101 Notified" || page === "Assigned Tickets") {
            columns = [
                { field: 'id', headerName: '#NUMBER', minWidth: 100, flex: 1 },
                { field: 'summary', headerName: 'SUMMARY', minWidth: 300, flex: 1 },
                { field: 'device', headerName: 'DEVICE', minWidth: 200, flex: 1 },
                { field: 'type', headerName: 'TYPE', minWidth: 200, flex: 1 },
                { field: 'assigned', headerName: 'ASSIGNED', minWidth: 200, flex: 1 },
                { field: 'raisedBy', headerName: 'RAISED BY', minWidth: 100, flex: 1 },
                {
                    field: 'status', headerName: 'STATUS', minWidth: 170, flex: 1,
                    sortComparator: (v1, v2) => v1.localeCompare(v2),
                    renderCell: (params: any) => (
                        <Status className="w-75" text={params.value} />
                    ),
                    valueGetter: (params) => params.value.props.text
                },
                {
                    field: 'priority', headerName: 'PRIORITY', minWidth: 170, flex: 1,
                    sortComparator: (v1, v2) => v1.localeCompare(v2),
                    renderCell: (params: any) => (
                        <Status className="w-75" text={params.value} />
                    ),
                    valueGetter: (params) => params.value.props.text
                },
                { field: 'scheduled', headerName: 'SCHEDULED', minWidth: 100, flex: 1 },
                { field: 'raised', headerName: 'DATE RAISED', minWidth: 100, flex: 1 },
            ]
        }
        if (page === "Assign Admin") {
            columns = [
                { field: 'id', headerName: 'USER ID', minWidth: 300, flex: 1 },
                { field: 'name', headerName: 'FULL NAME', minWidth: 300, flex: 1 },
                { field: 'email', headerName: 'EMAIL', minWidth: 300, flex: 1 },
            ]
        }
        if (page === "Knowledgebase") {
            columns = [
                { field: 'id', headerName: '#NUMBER', minWidth: 100, flex: 1 },
                { field: 'summary', headerName: 'SUMMARY', minWidth: 300, flex: 1 },
                { field: 'device', headerName: 'DEVICE', minWidth: 200, flex: 1 },
                { field: 'type', headerName: 'TYPE', minWidth: 200, flex: 1 },
                {
                    field: 'status', headerName: 'STATUS', minWidth: 170, flex: 1,
                    sortComparator: (v1, v2) => v1.localeCompare(v2),
                    renderCell: (params: any) => (
                        <Status className="w-75" text={params.value} />
                    ),
                    valueGetter: (params) => params.value.props.text
                },
                {
                    field: 'resolution', headerName: 'RESOLUTION', minWidth: 170, flex: 1,
                    sortComparator: (v1, v2) => v1.localeCompare(v2),
                    renderCell: (params: any) => (
                        <Status className="w-75" text={params.value} />
                    ),
                    valueGetter: (params) => params.value.props.text
                },
            ]
        }
        return columns
    }

    useEffect(() => {
        allData.map((item) => {
            if (selectionModel.length > 0 && item.ticketId.toString() === selectionModel[0].toString().slice(1)) {
                setSelectedTicket(item)
            }
        })
    }, [selectionModel])

    const getDepartmentKey = (dep: string) => {
        if (dep === "Contracts - Office") {
            return "cOffice"
        }
        if (dep === "Contracts - Site") {
            return "cSite"
        }
        if (dep === "Design") {
            return "cOffice"
        }
        if (dep === "Directors") {
            return "directors"
        }
        if (dep === "Sales") {
            return "sales"
        }
        if (dep === "Process") {
            return "process"
        }
        if (dep === "Finance & Warehouse") {
            return "fAndW"
        }
        if (dep === "Remedials - FPS") {
            return "rem"
        }
        if (dep === "Remedials - LPS") {
            return "rem"
        }
        if (dep === "Rope Access") {
            return "ropeAccess"
        }
        if (dep === "Test & Inspection Operations") {
            return "tIOps"
        }
        if (dep === "Test & Inspection Sales") {
            return "tISales"
        }
        if (dep === "Test & Inspection Site") {
            return "tISite"
        }
        if (dep === "Training & PPE") {
            return "trainAndPPE"
        }
        return "misc"
    }

    function convertMinutesToHours(minutes: number) {
        var hours = minutes / 60;
        return hours
      }

    const getGraphData = () => {
        const data = [
            {
                name: "Jan",
                cOffice: 0,
                cSite: 0,
                directors: 0,
                fAndW: 0,
                rem: 0,
                ropeAccess: 0,
                tIOps: 0,
                tISales: 0,
                tISite: 0,
                trainAndPPE: 0,
                misc: 0,
                process: 0,
                sales: 0
            },
            {
                name: "Feb",
                cOffice: 0,
                cSite: 0,
                directors: 0,
                fAndW: 0,
                rem: 0,
                ropeAccess: 0,
                tIOps: 0,
                tISales: 0,
                tISite: 0,
                trainAndPPE: 0,
                misc: 0,
                process: 0,
                sales: 0
            },
            {
                name: "Mar",
                cOffice: 0,
                cSite: 0,
                directors: 0,
                fAndW: 0,
                rem: 0,
                ropeAccess: 0,
                tIOps: 0,
                tISales: 0,
                tISite: 0,
                trainAndPPE: 0,
                misc: 0,
                process: 0,
                sales: 0
            },
            {
                name: "Apr",
                cOffice: 0,
                cSite: 0,
                directors: 0,
                fAndW: 0,
                rem: 0,
                ropeAccess: 0,
                tIOps: 0,
                tISales: 0,
                tISite: 0,
                trainAndPPE: 0,
                misc: 0,
                process: 0,
                sales: 0
            },
            {
                name: "May",
                cOffice: 0,
                cSite: 0,
                directors: 0,
                fAndW: 0,
                rem: 0,
                ropeAccess: 0,
                tIOps: 0,
                tISales: 0,
                tISite: 0,
                trainAndPPE: 0,
                misc: 0,
                process: 0,
                sales: 0
            },
            {
                name: "Jun",
                cOffice: 0,
                cSite: 0,
                directors: 0,
                fAndW: 0,
                rem: 0,
                ropeAccess: 0,
                tIOps: 0,
                tISales: 0,
                tISite: 0,
                trainAndPPE: 0,
                misc: 0,
                process: 0,
                sales: 0
            },
            {
                name: "Jul",
                cOffice: 0,
                cSite: 0,
                directors: 0,
                fAndW: 0,
                rem: 0,
                ropeAccess: 0,
                tIOps: 0,
                tISales: 0,
                tISite: 0,
                trainAndPPE: 0,
                misc: 0,
                process: 0,
                sales: 0
            },
            {
                name: "Aug",
                cOffice: 0,
                cSite: 0,
                directors: 0,
                fAndW: 0,
                rem: 0,
                ropeAccess: 0,
                tIOps: 0,
                tISales: 0,
                tISite: 0,
                trainAndPPE: 0,
                misc: 0,
                process: 0,
                sales: 0
            },
            {
                name: "Sep",
                cOffice: 0,
                cSite: 0,
                directors: 0,
                fAndW: 0,
                rem: 0,
                ropeAccess: 0,
                tIOps: 0,
                tISales: 0,
                tISite: 0,
                trainAndPPE: 0,
                misc: 0,
                process: 0,
                sales: 0
            },
            {
                name: "Oct",
                cOffice: 0,
                cSite: 0,
                directors: 0,
                fAndW: 0,
                rem: 0,
                ropeAccess: 0,
                tIOps: 0,
                tISales: 0,
                tISite: 0,
                trainAndPPE: 0,
                misc: 0,
                process: 0,
                sales: 0
            },
            {
                name: "Nov",
                cOffice: 0,
                cSite: 0,
                directors: 0,
                fAndW: 0,
                rem: 0,
                ropeAccess: 0,
                tIOps: 0,
                tISales: 0,
                tISite: 0,
                trainAndPPE: 0,
                misc: 0,
                process: 0,
                sales: 0
            },
            {
                name: "Dec",
                cOffice: 0,
                cSite: 0,
                directors: 0,
                fAndW: 0,
                rem: 0,
                ropeAccess: 0,
                tIOps: 0,
                tISales: 0,
                tISite: 0,
                trainAndPPE: 0,
                misc: 0,
                process: 0,
                sales: 0
            },
        ];

        allData.map((ticket) => {
            if(ticket.status === "Completed") {
                const month = moment(ticket.dateRaised, "DD/MM/YYYY").format("MMM")
                const year = moment(ticket.dateRaised, "DD/MM/YYYY").format("YYYY")
                data.map((line) => {
                    if(line.name === month && ticket.department !== undefined && ticket.timeTaken && year === "2024") {
                        const split = ticket.timeTaken.split("-")
                        let time = parseInt(split[0])
                        if(split[1] === "minutes") {
                            time = convertMinutesToHours(parseInt(split[0]))
                        }
                        line[getDepartmentKey(ticket.department)] = line[getDepartmentKey(ticket.department)] + parseFloat(time.toFixed(1))
                    }
                })
            }
        })
        projects.map((project) => {
            project.tickets.Completed.map((projectTicket) => {
                if(projectTicket.timeSpent) {
                    const split = projectTicket.timeSpent.split("-")
                    let time = parseInt(split[0])
                    if(split[1] === "minutes") {
                        time = convertMinutesToHours(parseInt(split[0]))
                    }
                    data.map((line) => {
                        if(line.name === moment(projectTicket.dateRaised).format("MMM") && moment(projectTicket.dateRaised).format("YYYY") === "2024" && projectTicket.department) {
                            line[getDepartmentKey(projectTicket.department)] = line[getDepartmentKey(projectTicket.department)] + parseFloat(time.toFixed(1))
                        }
                    })
                }
            })
        })
        return data
    }

    return (
        <>
            {page === "Reports" &&
                <div className="d-flex flex-row p-1">
                    <div className="w-100 graph-container">
                        <ResponsiveContainer width="100%" height="100%">
                            <LineChart
                                width={500}
                                height={300}
                                data={getGraphData()}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis label="Hours"/>
                                <Tooltip />
                                <Legend />
                                <Line name="Contracts - Office" type="monotone" dataKey="cOffice" stroke="#003f5c" />
                                <Line name="Contracts - Site" type="monotone" dataKey="cSite" stroke="#2f4b7c" />
                                <Line name="Directors" type="monotone" dataKey="directors" stroke="#a05195" />
                                <Line name="Finance & Warehouse" type="monotone" dataKey="fAndW" stroke="#d45087" />
                                <Line name="Remedials" type="monotone" dataKey="rem" stroke="#ff7c43" />
                                <Line name="Rope Access" type="monotone" dataKey="ropeAccess" stroke="#ffa600" />
                                <Line name="T&I Operations" type="monotone" dataKey="tIOps" stroke="#ff47b1" />
                                <Line name="T&I Sales" type="monotone" dataKey="tISales" stroke="#de56d6" />
                                <Line name="T&I Site" type="monotone" dataKey="tISite" stroke="#9d69f2" />
                                <Line name="Training & PPE" type="monotone" dataKey="trainAndPPE" stroke="#0078ff" />
                                <Line name="Process" type="monotone" dataKey="process" stroke="#00e9c0" />
                                <Line name="Sales" type="monotone" dataKey="sales" stroke="#00ff32" />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            }
            {page === "Assigned Tickets" &&
                <Container onSubChange={(ticket) => onSubChange(ticket)} addNewComment={(ticket) => addNewComment(ticket)} onAssignedChange={(value, ticket) => onAssignedChange(value, ticket)} onStatusPriorityChange={(type, value, ticket) => onStatusPriorityChange(type, value, ticket)} admins={admins} closeTicket={() => setViewing(false)} viewTicket={viewing} user={user} ticket={selectedTicket}>
                    <DataGrid
                        rows={getRows()}
                        columns={getColumns()}
                        onRowSelectionModelChange={(newSelectionModel) => {
                            setSelectionModel(newSelectionModel);
                        }}
                        onRowClick={() => setViewing(true)}
                        rowSelectionModel={selectionModel}
                    />
                </Container>
            }
            {page === "High Priority" &&
                <Container onSubChange={(ticket) => onSubChange(ticket)} addNewComment={(ticket) => addNewComment(ticket)} onAssignedChange={(value, ticket) => onAssignedChange(value, ticket)} onStatusPriorityChange={(type, value, ticket) => onStatusPriorityChange(type, value, ticket)} admins={admins} closeTicket={() => setViewing(false)} viewTicket={viewing} user={user} ticket={selectedTicket}>
                    <DataGrid
                        rows={getRows()}
                        columns={getColumns()}
                        onRowSelectionModelChange={(newSelectionModel) => {
                            setSelectionModel(newSelectionModel);
                        }}
                        onRowClick={() => setViewing(true)}
                        rowSelectionModel={selectionModel}
                    />
                </Container>
            }
            {page === "Completed" &&
                <Container onSubChange={(ticket) => onSubChange(ticket)} addNewComment={(ticket) => addNewComment(ticket)} onAssignedChange={(value, ticket) => onAssignedChange(value, ticket)} onStatusPriorityChange={(type, value, ticket) => onStatusPriorityChange(type, value, ticket)} admins={admins} closeTicket={() => setViewing(false)} viewTicket={viewing} user={user} ticket={selectedTicket}>
                    <DataGrid
                        rows={getRows()}
                        columns={getColumns()}
                        onRowSelectionModelChange={(newSelectionModel) => {
                            setSelectionModel(newSelectionModel);
                        }}
                        onRowClick={() => setViewing(true)}
                        rowSelectionModel={selectionModel}
                    />
                </Container>
            }
            {page === "1101 Notified" &&
                <Container onSubChange={(ticket) => onSubChange(ticket)} addNewComment={(ticket) => addNewComment(ticket)} onAssignedChange={(value, ticket) => onAssignedChange(value, ticket)} onStatusPriorityChange={(type, value, ticket) => onStatusPriorityChange(type, value, ticket)} admins={admins} closeTicket={() => setViewing(false)} viewTicket={viewing} user={user} ticket={selectedTicket}>
                    <DataGrid
                        rows={getRows()}
                        columns={getColumns()}
                        onRowSelectionModelChange={(newSelectionModel) => {
                            setSelectionModel(newSelectionModel);
                        }}
                        onRowClick={() => setViewing(true)}
                        rowSelectionModel={selectionModel}
                    />
                </Container>
            }
            {page === "Assign Admin" &&
                <div className="assetReg" onContextMenu={handleContextMenu}>
                    <DataGrid
                        rows={getRows()}
                        columns={getColumns()}
                        onRowSelectionModelChange={(newSelectionModel) => {
                            setSelectionModel(newSelectionModel);
                        }}
                        rowSelectionModel={selectionModel}
                    />
                    <Menu
                        open={contextMenu !== null}
                        onClose={handleClose}
                        anchorReference="anchorPosition"
                        anchorPosition={
                            contextMenu !== null
                                ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                                : undefined
                        }
                    >
                        <MenuItem onClick={() => {
                            setCreate(true)
                            handleClose()
                        }}><span className="d-flex flex-row align-items-center">
                                <FaFileCirclePlus color="white" className="mr1" />
                                Add new admin
                            </span>
                        </MenuItem>
                    </Menu>
                </div>
            }
            {page === "Knowledgebase" &&
                <KnowledgeContainer newKnowledge={(item) => newKnowledge(item)} allKnowledge={knowledgebase} admins={admins} closeTicket={() => setViewing(false)} viewTicket={viewing} user={user} ticket={selectedTicket}>
                    <DataGrid
                        rows={getRows()}
                        columns={getColumns()}
                        onRowSelectionModelChange={(newSelectionModel) => {
                            setSelectionModel(newSelectionModel);
                        }}
                        onRowClick={() => setViewing(true)}
                        rowSelectionModel={selectionModel}
                    />
                </KnowledgeContainer>
            }
        </>
    )
}

export default Dashboard