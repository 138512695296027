import { SubTask, User, graphAPIData, ticket } from "../Pages/AuthenticatedHome"
import wave from "../Images/wave.png"
import Status from "./Status"
import { Button, Form, Modal } from "react-bootstrap"
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from 'draft-js';
import { FaX } from "react-icons/fa6";
import { useEffect, useState } from "react";
import { allUsers } from "../TempData/TempData";
import AdminStatusDropdown from "./AdminStatusDropdown";
import AssignedDropdown from "./AssignedDropdown";
import { getDownloadURL, getStorage, ref } from 'firebase/storage';
import moment from "moment";
import draftToHtml from 'draftjs-to-html';
import { DataGrid, GridCellEditStopParams, GridColDef, GridRowSelectionModel, GridRowsProp, MuiEvent } from "@mui/x-data-grid";
import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import ReactDatePicker from "react-datepicker";
interface Props {
    children: React.ReactNode
    ticket?: ticket
    user: graphAPIData
    admins: User[]
    viewTicket: boolean
    closeTicket: () => void
    onAssignedChange: (value: User, ticket: ticket) => void
    onStatusPriorityChange: (type: string, value: string, ticket: ticket) => void
    addNewComment: (ticket: ticket) => void
    onSubChange: (ticket: ticket) => void
}

const Container = ({ children, ticket, user, viewTicket, closeTicket, onSubChange, admins, onStatusPriorityChange, onAssignedChange, addNewComment }: Props) => {
    const [editorState, setEditorState] = useState<EditorState>()
    const [status, setStatus] = useState<string>("")
    const [priority, setPriority] = useState<string>("")
    const [assigned, setAssigned] = useState<string>("")
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
    const [tableRows, setTableRows] = useState<GridRowsProp>([])
    const [imageURL, setImageURL] = useState<string>("")
    const [open, setOpen] = useState<boolean>(false)
    const [ScheduledDate, setScheduledDate] = useState<Date | undefined>(undefined);
    const mobileMedia = window.matchMedia("(max-width: 480px)").matches
    const [show, setShow] = useState(false);
    const [completedTicket, setCompletedTicket] = useState<ticket | undefined>(undefined)
    const [department, setDepartment] = useState<string>("")
    const [timeSpent, setTimeSpent] = useState<string>("")
    const [timeType, setTimeType] = useState<string>("hours")
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const storage = getStorage()
    const imageRef = ref(storage, `Files/${ticket?.file}`)
    const getFile = () => {
        getDownloadURL(imageRef).then((url) => setImageURL(url))
    }

    const addComment = async () => {
        const dateTime = moment().format('h:mm A - DD/MM/YYYY')
        if (ticket && editorState) {
            const comment = draftToHtml(convertToRaw(editorState.getCurrentContent()))
            const temp: ticket = ticket
            let commentArr: { comment: string, user: User, date: string }[] = []
            if (temp.comments) {
                commentArr = temp.comments
            }
            commentArr.push(
                {
                    comment: comment,
                    user: { email: user.mail, id: user.id, name: user.displayName },
                    date: dateTime
                }
            )
            temp.comments = commentArr
            addNewComment(temp)
            setEditorState(undefined)
        }
    }

    const addScheduled = (date: Date) => {
        if (ticket) {
            const temp = ticket
            temp.scheduledDate = date
            onSubChange(temp)
        }
    }

    const columns: GridColDef[] = [
        { field: 'description', headerName: 'DESCRIPTION', minWidth: 200, flex: 1 },
        {
            field: 'status', headerName: 'STATUS', maxWidth: 170, flex: 1,
            editable: true,
            sortComparator: (v1, v2) => v1.localeCompare(v2),
            renderCell: (params: any) => (
                <Status className="w-100 " text={params.value} />
            ),
            valueGetter: (params) => params.value.props.text,
            valueSetter: (params) => {
                return { ...params.row, status: <Status className="w-100 " text={params.value} /> }
            },
            type: "singleSelect",
            valueOptions: ["Queued", "In progress", "Awaiting action", "Scheduled", "Not Applicable", "Completed", "Escalated to 1101"]
        },
        { field: 'due', headerName: 'DUE', maxWidth: 170, flex: 1 },
    ];


    useEffect(() => {
        setImageURL("")
        if (ticket) {
            setStatus(ticket?.status)
            setPriority(ticket.priority)
            setAssigned(ticket.assigned?.name || "")
            console.log(ticket.scheduledDate)
            if (ticket.scheduledDate) {
                //@ts-ignore
                setScheduledDate(new Date(ticket.scheduledDate.seconds * 1000))
            }
            if (ticket?.file) {
                getFile()
            }
            if (ticket.subTasks) {
                const rows: GridRowsProp = ticket.subTasks.map((sub, index) => {
                    return {
                        id: "#" + sub.id,
                        description: sub.description,
                        status: <Status className="w-100" text={sub.status} />,
                        due: sub.due || "Not listed"
                    }
                })
                setTableRows(rows)
            }
        }
    }, [ticket])


    const handleStatusChange = (type: string, value: string, ticket: ticket) => {
        setStatus(value)
        if (value === "Completed") {
            setCompletedTicket(ticket)
            handleShow()
        } else {
            onStatusPriorityChange(type, value, ticket)
        }
    }

    const completeTicket = () => {
        if (completedTicket) {
            const temp = completedTicket
            temp.timeTaken = `${timeSpent}-${timeType}`
            temp.department = department
            onStatusPriorityChange("status", "Completed", temp)
            handleClose()
        }
    }

    function formatDate(dateString: string) {
        // Check if the date is in "Tue Sep 10 2024 13:03:45 GMT+0100" format
        if (moment(dateString, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ', true).isValid()) {
            return moment(dateString).format('DD/MM/YYYY');
        }

        // Check if the date is in "10/09/24" format
        if (moment(dateString, 'DD/MM/YYYY', true).isValid()) {
            return moment(dateString, 'DD/MM/YYYY').format('DD/MM/YYYY');
        }

        // Fallback for unrecognized formats
        return 'Invalid Date';
    }

    function formatTime(dateString: string) {
        // Check if the date is in "Tue Sep 10 2024 13:03:45 GMT+0100" format
        if (moment(dateString, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ', true).isValid()) {
            return moment(dateString).format('HH:mm');
        }

        // Check if the date is in "10/09/24" format
        if (moment(dateString, 'DD/MM/YYYY', true).isValid()) {
            return moment(dateString, 'DD/MM/YYYY').format('HH:mm');
        }

        // Fallback for unrecognized formats
        return 'Invalid Date';
    }

    return (
        <div className="containerES">
            <Modal show={show} onHide={handleClose} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Completion details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-2">
                        <p className="containerES-ticket-content__label">Department:</p>
                        <Form.Select value={department} onChange={(e) => setDepartment(e.currentTarget.value)}>
                            <option value="">Select department...</option>
                            <option value="Contracts - Office">Contracts - Office</option>
                            <option value="Contracts - Site">Contracts - Site</option>
                            <option value="Design">Design</option>
                            <option value="Directors">Directors</option>
                            <option value="Finance & Warehouse">Finance & Warehouse</option>
                            <option value="Process">Process</option>
                            <option value="Remedials - FPS">Remedials - FPS</option>
                            <option value="Remedials - LPS">Remedials - LPS</option>
                            <option value="Rope Access">Rope Access</option>
                            <option value="Sales">Sales</option>
                            <option value="Test & Inspection Operations">Test & Inspection Operations</option>
                            <option value="Test & Inspection Sales">Test & Inspection Sales</option>
                            <option value="Test & Inspection Site">Test & Inspection Site</option>
                            <option value="Training & PPE">Training & PPE</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className='mb-2'>
                        <p className="containerES-ticket-content__label">Time spent:</p>
                        <div className="d-flex flex-row">
                            <Form.Control
                                value={timeSpent}
                                onChange={(value) => setTimeSpent(value.currentTarget.value)}
                                className="w-75 timeSpent"
                                type='Number'
                                placeholder={`Enter ${timeType} spent...`} />
                            <Form.Select className="w-25 timeType" value={timeType} onChange={(e) => setTimeType(e.currentTarget.value)}>
                                <option value="hours">Hours</option>
                                <option value="minutes">Minutes</option>
                            </Form.Select>
                        </div>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="buttonES" onClick={completeTicket}>
                        Complete ticket
                    </Button>
                </Modal.Footer>
            </Modal>
            <div className={`containerES-children ${!viewTicket && "w-100"}`}>
                {children}
            </div>
            {viewTicket && ticket &&
                <div className="containerES-ticket">
                    <img className="containerES-ticket__wave" src={wave} />
                    <div className="containerES-ticket-content">
                        <div className="d-flex flex-row justify-content-between align-items-center">
                            <p className="containerES-ticket-content__id">#{ticket?.ticketId}</p>
                            <FaX onClick={closeTicket} className="containerES-ticket-content__close" color="white" size={24} />
                        </div>
                        <Lightbox
                            open={open}
                            close={() => setOpen(false)}
                            slides={[
                                {
                                    src: imageURL
                                },
                            ]}
                        />
                        <h2 className="containerES-ticket-content__title">{ticket?.subject}</h2>
                        <p className="containerES-ticket-content__label">Description:</p>
                        <div className="containerES-ticket-content-description" dangerouslySetInnerHTML={{ __html: ticket?.description || "" }}></div>
                        {imageURL !== "" &&
                            <>
                                <p className="containerES-ticket-content__label">Attached image:</p>
                                <img onClick={() => setOpen(true)} className="containerES-ticket-content-thumb" src={imageURL} />
                            </>
                        }
                        <div className="containerES-ticket-content-row mb-0">
                            <div className={mobileMedia ? "d-flex flex-column w-100" : "d-flex flex-column w-25 mr1"}>
                                {(admins.find((e) => e.id === user.id) && ticket.status === "Scheduled") ?
                                    <>
                                        <p className="containerES-ticket-content__label">Scheduled date:</p>
                                        <ReactDatePicker
                                            onChange={(date: Date) => {
                                                addScheduled(date)
                                                setScheduledDate(date)
                                            }}
                                            locale="en-GB"
                                            className='form-control'
                                            placeholderText="Enter date..."
                                            calendarClassName='w-100'
                                            dateFormat={"dd/MM/yyyy"}
                                            selected={ScheduledDate} />
                                    </>
                                    :
                                    <>
                                        <p className="containerES-ticket-content__label">Date raised:</p>
                                        <p className="containerES-ticket-content__value">{formatDate(ticket?.dateRaised)}</p>
                                    </>
                                }

                            </div>
                            <div className={mobileMedia ? "d-flex flex-column w-100" : "d-flex flex-column w-25 mr1"}>
                                {(admins.find((e) => e.id === user.id) && ticket.status === "Scheduled") ?
                                    <>
                                        <p className="containerES-ticket-content__label">Date raised:</p>
                                        <p className="containerES-ticket-content__value">{formatDate(ticket?.dateRaised)}</p>
                                    </>
                                    :
                                    <>
                                        <p className="containerES-ticket-content__label">Time raised:</p>
                                        <p className="containerES-ticket-content__value">{formatTime(ticket.dateRaised)}</p>
                                    </>
                                }
                            </div>
                            <div className={mobileMedia ? "d-flex flex-column w-100" : "d-flex flex-column w-25 mr1"}>
                                {(admins.find((e) => e.id === user.id) && ticket.status === "Scheduled") ?
                                    <>
                                        <p className="containerES-ticket-content__label">Time raised:</p>
                                        <p className="containerES-ticket-content__value">{formatTime(ticket.dateRaised)}</p>

                                    </>
                                    :
                                    <>
                                        <p className="containerES-ticket-content__label">First noticed:</p>
                                        <p className="containerES-ticket-content__value">{moment(ticket?.firstNoticed).format("DD/MM/YYYY")}</p>
                                    </>
                                }
                            </div>
                            {!mobileMedia &&
                                <div className="d-flex flex-column w-25">
                                    {(admins.find((e) => e.id === user.id) && ticket.status === "Scheduled") &&
                                        <>
                                            <p className="containerES-ticket-content__label">First noticed:</p>
                                            <p className="containerES-ticket-content__value">{moment(ticket?.firstNoticed).format("DD/MM/YYYY")}</p>
                                        </>
                                    }
                                </div>
                            }
                        </div>
                        <div className="containerES-ticket-content-row">
                            <div className={mobileMedia ? "d-flex flex-column w-100 mb-3" : "d-flex flex-column w-25 mr1"}>
                                <p className="containerES-ticket-content__label">Status:</p>
                                {admins.find((e) => e.id === user.id) ?
                                    <AdminStatusDropdown ticket={ticket} onChange={(type, value, ticket) => {
                                        handleStatusChange(type, value, ticket)
                                    }} initial={status} type="status" />
                                    :
                                    <Status className={mobileMedia ? "d-flex w-50" : "w-90 d-flex"} text={ticket?.status} />
                                }
                            </div>
                            <div className={mobileMedia ? "d-flex flex-column w-100 mb-3" : "d-flex flex-column w-25 mr1"}>
                                <p className="containerES-ticket-content__label">Assigned:</p>
                                {admins.find((e) => e.id === user.id) ? <AssignedDropdown ticket={ticket} onChange={(value, ticket) => {
                                    setAssigned(value.name)
                                    onAssignedChange(value, ticket)
                                }} initial={assigned} /> :
                                    <p className="containerES-ticket-content__value">{ticket?.assigned?.name}</p>
                                }
                            </div>
                            <div className={mobileMedia ? "d-flex flex-column w-100" : "d-flex flex-column w-25 mr1"}>
                                <p className="containerES-ticket-content__label">Raised by:</p>
                                <p className="containerES-ticket-content__value">{ticket?.raisedBy.name}</p>
                            </div>
                            <div className={mobileMedia ? "d-flex flex-column w-100" : "d-flex flex-column w-25"}>
                                <p className="containerES-ticket-content__label">Location:</p>
                                <p className="containerES-ticket-content__value">{ticket?.location}</p>
                            </div>
                        </div>
                        <div className="containerES-ticket-content-row">
                            <div className={mobileMedia ? "d-flex flex-column w-100 mb-3" : "d-flex flex-column w-25 mr1"}>
                                <p className="containerES-ticket-content__label">Priority:</p>
                                {admins.find((e) => e.id === user.id) ?
                                    <AdminStatusDropdown ticket={ticket} onChange={(type, value, ticket) => {
                                        setPriority(value)
                                        onStatusPriorityChange(type, value, ticket)
                                    }} initial={priority} type="priority" />
                                    :
                                    <Status className={mobileMedia ? "d-flex w-50" : "w-90 d-flex"} text={ticket?.priority} />
                                }
                            </div>
                            <div className={mobileMedia ? "d-flex flex-column w-100" : "d-flex flex-column w-25 mr1"}>
                                <p className="containerES-ticket-content__label">Device:</p>
                                <p className="containerES-ticket-content__value">{ticket?.device || "Not assigned"}</p>
                            </div>
                            <div className={mobileMedia ? "d-flex flex-column w-100" : "d-flex flex-column w-25 mr1"}>
                                <p className="containerES-ticket-content__label">Type:</p>
                                <p className="containerES-ticket-content__value">{ticket?.type || "Not assigned"}</p>
                            </div>
                            {!mobileMedia &&
                                <div className="d-flex flex-column w-25">
                                </div>
                            }

                        </div>
                        {(ticket.subTasks && ticket.subTasks.length > 0) &&
                            <div className="mb-4">
                                <p className="containerES-ticket-content__label">Subtasks:</p>
                                <DataGrid
                                    rows={tableRows}
                                    columns={columns}
                                    initialState={{
                                        pagination: { paginationModel: { pageSize: 10 } }
                                    }}
                                    pageSizeOptions={[10, 20, 30]}
                                    onRowSelectionModelChange={(newSelectionModel) => {
                                        setSelectionModel(newSelectionModel);
                                    }}
                                    rowSelectionModel={selectionModel}
                                    processRowUpdate={(updatedRow, originalRow) => {
                                        const temp = ticket
                                        if (temp.subTasks) {
                                            temp.subTasks.map((sub) => {
                                                if ("#" + sub.id == updatedRow.id) {
                                                    sub.status = updatedRow.status.props.text
                                                }
                                            })
                                        }
                                        onSubChange(temp)
                                        return updatedRow
                                    }
                                    }
                                    onProcessRowUpdateError={(error) => console.log(error)}
                                />
                            </div>
                        }
                        <p className="containerES-ticket-content__label">Comments:</p>
                        {ticket?.comments?.map((comment) => {
                            if (comment.user.id === user.id) {
                                return (
                                    <div className="containerES-ticket-content-comment containerES-ticket-content-comment--blue">
                                        <div className="containerES-ticket-content-comment-bubble containerES-ticket-content-comment-bubble--blue">
                                            <div className="d-flex w-100 justify-content-between">
                                                <p className="containerES-ticket-content-comment-bubble__name">{comment.user.name}</p>
                                                <p className="containerES-ticket-content-comment-bubble__date">{comment.date}</p>
                                            </div>
                                            <div className="containerES-ticket-content-comment-bubble__comment" dangerouslySetInnerHTML={{ __html: comment.comment }}>
                                            </div>
                                        </div>
                                    </div>
                                )
                            } else {
                                return (
                                    <div className="containerES-ticket-content-comment">
                                        <div className="containerES-ticket-content-comment-bubble">
                                            <div className="d-flex w-100 justify-content-between">
                                                <p className="containerES-ticket-content-comment-bubble__name">{comment.user.name}</p>
                                                <p className="containerES-ticket-content-comment-bubble__date">{comment.date}</p>
                                            </div>
                                            <div className="containerES-ticket-content-comment-bubble__comment" dangerouslySetInnerHTML={{ __html: comment.comment }}>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        })}
                        <Editor
                            placeholder='Enter comment...'
                            editorState={editorState}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName mb-2"
                            editorClassName="form-control editor-height"
                            toolbar={{
                                options: ['list', 'inline'],
                                inline: {
                                    options: ['bold', 'italic', 'underline']
                                },
                                list: {
                                    options: ['unordered'],
                                }
                            }}
                            onEditorStateChange={(e) => setEditorState(e)}
                        />
                        <div className="d-flex justify-content-end">
                            <Button onClick={() => addComment()} className="buttonES">Send message</Button>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default Container