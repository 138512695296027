import { useEffect, useState } from "react"
import { User } from "../AuthenticatedHome"
import { DataGrid, GridColDef, GridRowSelectionModel, GridRowsProp } from "@mui/x-data-grid"
import Status from "../../Components/Status"
import { Menu, MenuItem } from "@mui/material"
import { FaArrowLeft, FaFileCirclePlus } from "react-icons/fa6"
import ProjectContainer from "../../Components/ProjectContainer"
import DragContainer from "../../Components/DragContainer"

export interface ProjectTicket {
    id: string
    dateRaised?: string
    title: string
    description: string
    prefix: string
    priority?: string
    user?: User
    department?: string
    timeSpent?: string
}

export interface Project {
    id: string;
    projectName: string;
    tickets: {
        Queued: ProjectTicket[];
        "In Progress": ProjectTicket[];
        Testing: ProjectTicket[];
        Completed: ProjectTicket[];
    }
    status: string
}

interface Props {
    allData: Project[]
    saveProject: (project: Project) => void
    onTicketCreate: (ticket: ProjectTicket, project: Project) => void
    saveChange: (project: Project) => void
}


const Developer = ({ allData, saveProject, onTicketCreate, saveChange }: Props) => {
    const [viewing, setViewing] = useState<boolean>(false)
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
    const [selectedProject, setSelectedProject] = useState<Project>()
    const [create, setCreate] = useState<boolean>(false)


    const rows: GridRowsProp = allData.map((item, index) => {
        return {
            id: item.id,
            projectName: item.projectName,
            status: <Status className="w-75" text={item.status} />,
            queued: item?.tickets.Queued.length + (item?.tickets.Queued.length === 1 ? " Ticket" : " Tickets"),
            inProgress: item?.tickets["In Progress"].length + (item?.tickets["In Progress"].length === 1 ? " Ticket" : " Tickets"),
            testing: item?.tickets.Testing.length + (item?.tickets.Testing.length === 1 ? " Ticket" : " Tickets"),
            completed: item?.tickets.Completed.length + (item?.tickets.Completed.length === 1 ? " Ticket" : " Tickets"),
            tickets: item.tickets
        }
    })

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'PROJECT ID', maxWidth: 200, flex: 1 },
        { field: 'projectName', headerName: 'PROJECT NAME', minWidth: 400, flex: 1 },
        { field: 'queued', headerName: 'QUEUED', maxWidth: 200, flex: 1 },
        { field: 'inProgress', headerName: 'IN PROGRESS', maxWidth: 200, flex: 1 },
        { field: 'testing', headerName: 'TESTING', maxWidth: 200, flex: 1 },
        { field: 'completed', headerName: 'COMPLETED', maxWidth: 200, flex: 1 },
    ];

    const [contextMenu, setContextMenu] = useState<{
        mouseX: number;
        mouseY: number;
    } | null>(null);

    const handleContextMenu = (event: React.MouseEvent) => {
        event.preventDefault();
        setContextMenu(
            contextMenu === null
                ? {
                    mouseX: event.clientX + 2,
                    mouseY: event.clientY - 6,
                }
                : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                // Other native context menus might behave different.
                // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                null,
        );
    };

    const handleClose = () => {
        setContextMenu(null);
    };

    useEffect(() => {
        if(selectedProject) {
            allData.map((data) => {
                if(data.id === selectedProject.id) {
                    setSelectedProject(data)
                }
            })
        }
    }, [allData])
    return (
        <ProjectContainer
        projects={allData}
            create={create}
            closeProject={() => setCreate(false)}
            saveProject={(project) => saveProject(project)}
        >
            <>
                {viewing ?
                    <>
                        {selectedProject &&
                            <>
                                <a onClick={() => setViewing(false)} className="d-flex flex-row align-items-center back-arrow"><FaArrowLeft color="white" /> Return to table</a>
                                <DragContainer saveChange={(project) => saveChange(project)} onTicketCreate={(ticket, project) => onTicketCreate(ticket, project)} project={selectedProject} />
                            </>
                        }
                    </>
                    :
                    <div className="assetReg" onContextMenu={handleContextMenu}>
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            onRowSelectionModelChange={(newSelectionModel) => {
                                setSelectionModel(newSelectionModel);
                            }}
                            rowSelectionModel={selectionModel}
                            onRowClick={(item) => {
                                let temp: Project = {
                                    id: item.row.id,
                                    projectName: item.row.projectName,
                                    tickets: item.row.tickets,
                                    status: item.row.status.props.text
                                }
                                setSelectedProject(temp)
                                setViewing(true)
                            }}
                        />
                        <Menu
                            open={contextMenu !== null}
                            onClose={handleClose}
                            anchorReference="anchorPosition"
                            anchorPosition={
                                contextMenu !== null
                                    ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                                    : undefined
                            }
                        >
                            <MenuItem onClick={() => {
                                setCreate(true)
                                handleClose()
                            }}><span className="d-flex flex-row align-items-center">
                                    <FaFileCirclePlus color="white" className="mr1" />
                                    Create new project
                                </span>
                            </MenuItem>
                        </Menu>
                    </div>
                }
            </>
        </ProjectContainer>
    )
}

export default Developer