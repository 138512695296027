import { Button, Form } from "react-bootstrap"
import wave from "../../Images/wave.png"
import { FaArrowRightLong, FaArrowLeftLong } from "react-icons/fa6";
import { useState } from "react"
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import { FaCircleCheck } from "react-icons/fa6";
import { SubTask, User, ticket } from "../AuthenticatedHome";
import { EditorState } from "draft-js";
import moment from "moment";
import { toast } from "react-toastify";
import { Typeahead } from "react-bootstrap-typeahead";
import { allUsers } from "../../TempData/TempData";

interface Props {
    data: ticket[]
    user: User
    admins: User[]
    raiseTicket: (ticket: ticket) => void
    addFile: (file: File) => void
}

const RaiseTicket = ({ data, user, admins, raiseTicket, addFile }: Props) => {
    const [page, setPage] = useState<number>(1)
    const [noticedDate, setNoticedDate] = useState<Date>(new Date());
    const [summary, setSummary] = useState<string>("")
    const [description, setDescription] = useState<string>("")
    const [template, setTemplate] = useState<string>("Support ticket")
    const [device, setDevice] = useState<string>("")
    const [type, setType] = useState<string>("")
    const [startType, setStartType] = useState<string>("Site Operative")

    const [affect, setAffect] = useState<number>(1)
    const [file, setFile] = useState<File>()
    const [raisedBy, setRaisedBy] = useState<User>()
    const [blocked, setBlocked] = useState<number>(1)
    const [location, setLocation] = useState<string>("The office")

    const validate = () => {
        if (summary === "") {
            toast.error("Please enter a summary")
        }
        if (description === null) {
            toast.error("Please enter a description")
        }
        if (admins.find((e) => e.id === user.id)) {
            if (!raisedBy) {
                toast.error("Please add a raised by user")
            }
            if (summary !== "" && description !== null && raisedBy) {
                addTicket()
                setPage(4)
            }
        } else {
            if (summary !== "" && description !== null) {
                addTicket()
                setPage(4)
            }
        }
    }

    const addTicket = async () => {
        const id = 100001 + data.length
        let priority = "Low priority"
        if ((affect + blocked) === 3 || (affect + blocked) === 4) {
            priority = "Medium priority"
        }
        if ((affect + blocked) === 5 || (affect + blocked) === 6) {
            priority = "High priority"
        }
        if (user) {
            const tempRaisedBy: User = {
                email: user?.email,
                id: user?.id,
                name: user?.name
            }
            if (raisedBy && admins.find((e) => e.id === user.id)) {
                tempRaisedBy.email = raisedBy.email
                tempRaisedBy.name = raisedBy.name
                tempRaisedBy.id = raisedBy.id
            }
            const subTasks: SubTask[] = []
            if (template === "New starter (Office)") {
                const temp = [
                    {
                        "description": "Create ERP account",
                        "due": "Prior to start"
                    },
                    {
                        "description": "Add to 3CX & set up desk phone",
                        "due": "Prior to start"
                    },
                    {
                        "description": "Special permissions (finance, Bluebeam etc)",
                        "due": "Prior to start"
                    },
                    {
                        "description": "Sophos / Remote working permissions",
                        "due": "First day"
                    },
                    {
                        "description": "Add to Active Directory",
                        "due": "Prior to start"
                    },
                    {
                        "description": "Assign licences on O365",
                        "due": "Prior to start"
                    },
                    {
                        "description": "Order all hardware",
                        "due": "Prior to start"
                    },
                    {
                        "description": "Set up workstation",
                        "due": "Prior to start"
                    },
                    {
                        "description": "Allow access to Intranet",
                        "due": "Prior to start"
                    },
                    {
                        "description": "Order phone / tablet with cases and SIM",
                        "due": "Prior to start"
                    },
                    {
                        "description": "Set up Intune",
                        "due": "Prior to start"
                    },
                    {
                        "description": "Add to Nordpass and share",
                        "due": "Prior to start"
                    },
                    {
                        "description": "Add to Exclaimer",
                        "due": "Prior to start"
                    },
                    {
                        "description": "Add to mailing lists (Global, Engineers etc)",
                        "due": "Prior to start"
                    },
                    {
                        "description": "Add new hardware to Asset Register",
                        "due": "Prior to start"
                    },
                    {
                        "description": "Check hardware out on Asset Register after signing form",
                        "due": "First day"
                    },
                    {
                        "description": "Issue login details to employee",
                        "due": "First day"
                    },
                    {
                        "description": "Set up Authenticator",
                        "due": "First day"
                    },
                    {
                        "description": "Add to Company Directory",
                        "due": "First day"
                    },
                    {
                        "description": "Sign Equipment Acceptance Form",
                        "due": "First day"
                    },

                ]
                temp.map((item, index) => {
                    subTasks.push({
                        description: item.description,
                        due: item.due,
                        id: `${id}-${index}`,
                        assigned: {
                            email: "",
                            id: "",
                            name: "",
                        },
                        status: "Queued"
                    })
                })
            }
            if (template === "New starter (Site)") {
                const temp = [
                    {
                        "description": "Create App Account",
                        "Category": "Site staff",
                        "due": "Prior to start"
                    },
                    {
                        "description": "Set up phone / tablet and add apps",
                        "Category": "Site staff",
                        "due": "Prior to start"
                    },
                    {
                        "description": "Add key contacts to phone",
                        "Category": "Site staff",
                        "due": "Prior to start"
                    },
                    {
                        "description": "Sign into Emails / Intranet / OneDrive",
                        "Category": "Site staff",
                        "due": "First day"
                    },
                    {
                        "description": "App training",
                        "Category": "Site staff",
                        "due": "First day"
                    },
                    {
                        "description": "Add to Active Directory",
                        "Category": "All staff",
                        "due": "Prior to start"
                    },
                    {
                        "description": "Assign licences on O365",
                        "Category": "All staff",
                        "due": "Prior to start"
                    },
                    {
                        "description": "Order all hardware",
                        "Category": "All staff",
                        "due": "Prior to start"
                    },
                    {
                        "description": "Set up workstation",
                        "Category": "All staff",
                        "due": "Prior to start"
                    },
                    {
                        "description": "Allow access to Intranet",
                        "Category": "All staff",
                        "due": "Prior to start"
                    },
                    {
                        "description": "Order phone / tablet with cases and SIM",
                        "Category": "All staff",
                        "due": "Prior to start"
                    },
                    {
                        "description": "Set up Intune",
                        "Category": "All staff",
                        "due": "Prior to start"
                    },
                    {
                        "description": "Add to Nordpass and share",
                        "Category": "All staff",
                        "due": "Prior to start"
                    },
                    {
                        "description": "Add to mailing lists (Global, Engineers etc)",
                        "Category": "All staff",
                        "due": "Prior to start"
                    },
                    {
                        "description": "Issue login details to employee",
                        "Category": "All staff",
                        "due": "First day"
                    },
                    {
                        "description": "Set up Authenticator",
                        "Category": "All staff",
                        "due": "First day"
                    },
                    {
                        "description": "Add to Company Directory",
                        "Category": "All staff",
                        "due": "First day"
                    },
                    {
                        "description": "Sign Equipment Acceptance Form",
                        "due": "First day"
                    },
                    {
                        "description": "Add new hardware to Asset Register",
                        "due": "Prior to start"
                    },
                    {
                        "description": "Check hardware out on Asset Register after signing form",
                        "due": "First day"
                    },
                ]
                temp.map((item, index) => {
                    subTasks.push({
                        description: item.description,
                        due: item.due,
                        id: `${id}-${index}`,
                        assigned: {
                            email: "",
                            id: "",
                            name: "",
                        },
                        status: "Queued"
                    })
                })
            }
            if (template === "Leaver (Office)") {
                const temp = [
                    {
                        "description": "Immediately block sign in (if applicable)",
                        "Category": "All staff",
                        "due": "As requested"
                    },
                    {
                        "description": "Move to pending deletion on AD",
                        "Category": "All staff",
                        "due": "Day after leaving"
                    },
                    {
                        "description": "Remove from mailing lists",
                        "Category": "All staff",
                        "due": "Day after leaving"
                    },
                    {
                        "description": "Remove licencing on O365",
                        "Category": "All staff",
                        "due": "Day after leaving"
                    },
                    {
                        "description": "Disable / remove role on ERP",
                        "Category": "All staff",
                        "due": "Day after leaving"
                    },
                    {
                        "description": "Obtain and wipe phone & tablet",
                        "Category": "All staff",
                        "due": "Day after leaving"
                    },
                    {
                        "description": "Update asset register",
                        "Category": "All staff",
                        "due": "Day after leaving"
                    },
                    {
                        "description": "Remove from Company Directory",
                        "Category": "All staff",
                        "due": "Day after leaving"
                    },
                    {
                        "description": "Disable email and set as shared mailbox",
                        "Category": "Office staff",
                        "due": "Day after leaving"
                    },
                    {
                        "description": "Set forwarding rules for emails",
                        "Category": "Office staff",
                        "due": "Day after leaving"
                    },
                    {
                        "description": "Put out of office on email",
                        "Category": "Office staff",
                        "due": "Day after leaving"
                    },
                    {
                        "description": "Recover Bluebeam licence",
                        "Category": "Office staff",
                        "due": "Day after leaving"
                    },
                    {
                        "description": "Remove user from 3CX",
                        "Category": "Office staff",
                        "due": "Day after leaving"
                    },
                    {
                        "description": "Obtain and wipe computer",
                        "Category": "Office staff",
                        "due": "Day after leaving"
                    },
                    {
                        "description": "Remove from website",
                        "Category": "Office staff",
                        "due": "Day after leaving"
                    },
                    {
                        "description": "Obtain hardware and check in to Asset Register",
                        "due": "Day after leaving"
                    }
                ]
                temp.map((item, index) => {
                    subTasks.push({
                        description: item.description,
                        due: item.due,
                        id: `${id}-${index}`,
                        assigned: {
                            email: "",
                            id: "",
                            name: "",
                        },
                        status: "Queued"
                    })
                })
            }
            if (template === "Leaver (Site)") {
                const temp = [
                    {
                        "description": "Immediately block sign in (if applicable)",
                        "Category": "All staff",
                        "due": "As requested"
                    },
                    {
                        "description": "Move to pending deletion on AD",
                        "Category": "All staff",
                        "due": "Day after leaving"
                    },
                    {
                        "description": "Remove from mailing lists",
                        "Category": "All staff",
                        "due": "Day after leaving"
                    },
                    {
                        "description": "Remove licencing on O365",
                        "Category": "All staff",
                        "due": "Day after leaving"
                    },
                    {
                        "description": "Disable / remove role on ERP",
                        "Category": "All staff",
                        "due": "Day after leaving"
                    },
                    {
                        "description": "Obtain and wipe phone & tablet",
                        "Category": "All staff",
                        "due": "Day after leaving"
                    },
                    {
                        "description": "Update asset register",
                        "Category": "All staff",
                        "due": "Day after leaving"
                    },
                    {
                        "description": "Remove from Company Directory",
                        "Category": "All staff",
                        "due": "Day after leaving"
                    },
                    {
                        "description": "Obtain hardware and check in to Asset Register",
                        "due": "Day after leaving"
                    }
                ]
                temp.map((item, index) => {
                    subTasks.push({
                        description: item.description,
                        due: item.due,
                        id: `${id}-${index}`,
                        assigned: {
                            email: "",
                            id: "",
                            name: "",
                        },
                        status: "Queued"
                    })
                })
            }
            if (template === "Role change") {
                const temp = [
                    {
                        "description": "Change job title",
                        "due": "On date"
                    },
                    {
                        "description": "Change department on AD (if applicable)",
                        "due": "On date"
                    },
                    {
                        "description": "Update company directory",
                        "due": "On date"
                    },
                    {
                        "description": "Change job title / department on website",
                        "due": "On date"
                    },
                    {
                        "description": "Change job title on ERP",
                        "due": "On date"
                    },
                ]
                temp.map((item, index) => {
                    subTasks.push({
                        description: item.description,
                        due: item.due,
                        id: `${id}-${index}`,
                        assigned: {
                            email: "",
                            id: "",
                            name: "",
                        },
                        status: "Queued"
                    })
                })
            }
            if (template === "Hardware request") {
                let temp: {description: string, due: string}[] = []
                if(device === "Phone") {
                    temp = [
                        {
                            "description": "Approved by line manager",
                            "due": "Before order"
                        },
                        {
                            "description": "Approved by Callie McCabe",
                            "due": "Before order"
                        },
                        {
                            "description": "SIM allocation",
                            "due": "Before order"
                        },
                        {
                            "description": "Hardware ordered",
                            "due": "After approval"
                        },
                        {
                            "description": "Add to asset register",
                            "due": "On arrival"
                        },
                        {
                            "description": "Intune installed & signed in",
                            "due": "On arrival"
                        },
                        {
                            "description": "Outlook signed in",
                            "due": "On arrival"
                        },
                        {
                            "description": "Onedrive signed in",
                            "due": "On arrival"
                        },
                        {
                            "description": "Authenticator signed in",
                            "due": "On arrival"
                        },
                        {
                            "description": "SharePoint signed in",
                            "due": "On arrival"
                        },
                        {
                            "description": "PDF viewer installed",
                            "due": "On arrival"
                        },
                        {
                            "description": "Assign user in asset register",
                            "due": "On issue"
                        },
                        {
                            "description": "Hardware policy signed",
                            "due": "On issue"
                        },
                    ]
                }
                if(device === "Tablet") {
                    temp = [
                        {
                            "description": "Approved by line manager",
                            "due": "Before order"
                        },
                        {
                            "description": "Approved by Callie McCabe",
                            "due": "Before order"
                        },
                        {
                            "description": "SIM allocation",
                            "due": "Before order"
                        },
                        {
                            "description": "Hardware ordered",
                            "due": "After approval"
                        },
                        {
                            "description": "Add to asset register",
                            "due": "On arrival"
                        },
                        {
                            "description": "Intune installed & signed in",
                            "due": "On arrival"
                        },
                        {
                            "description": "Outlook signed in",
                            "due": "On arrival"
                        },
                        {
                            "description": "Onedrive signed in",
                            "due": "On arrival"
                        },
                        {
                            "description": "Authenticator signed in",
                            "due": "On arrival"
                        },
                        {
                            "description": "SharePoint signed in",
                            "due": "On arrival"
                        },
                        {
                            "description": "Eurosafe app installed",
                            "due": "On arrival"
                        },
                        {
                            "description": "PDF viewer installed",
                            "due": "On arrival"
                        },
                        {
                            "description": "Assign user in asset register",
                            "due": "On issue"
                        },
                        {
                            "description": "Hardware policy signed",
                            "due": "On issue"
                        },
                    ]
                }
                if(device === "Laptop") {
                    temp = [
                        {
                            "description": "Approved by line manager",
                            "due": "Before order"
                        },
                        {
                            "description": "Approved by Callie McCabe",
                            "due": "Before order"
                        },
                        {
                            "description": "Hardware ordered",
                            "due": "After approval"
                        },
                        {
                            "description": "Add to asset register",
                            "due": "On arrival"
                        },
                        {
                            "description": "Connect laptop to Eurosafe domain",
                            "due": "On arrival"
                        },
                        {
                            "description": "3CX installed",
                            "due": "On arrival"
                        },
                        {
                            "description": "Microsoft 365 apps installed",
                            "due": "On arrival"
                        },
                        {
                            "description": "ERP installed",
                            "due": "On arrival"
                        },
                        {
                            "description": "Connectwise installed",
                            "due": "On arrival"
                        },
                        {
                            "description": "Java installed",
                            "due": "On arrival"
                        },
                        {
                            "description": "ESET installed",
                            "due": "On arrival"
                        },
                        {
                            "description": "Silicon Motion installed",
                            "due": "On arrival"
                        },
                        {
                            "description": "Sophos installed",
                            "due": "On arrival"
                        },
                        {
                            "description": "Notepad++ installed",
                            "due": "On arrival"
                        },
                        {
                            "description": "Autodesk installed",
                            "due": "On arrival"
                        },
                        {
                            "description": "Bluebeam installed (if applicable)",
                            "due": "On arrival"
                        },
                        {
                            "description": "Autocad installed (if applicable)",
                            "due": "On arrival"
                        },
                        {
                            "description": "Outlook signed in",
                            "due": "On arrival"
                        },
                        {
                            "description": "SharePoint signed in",
                            "due": "On arrival"
                        },
                        {
                            "description": "Add RDS shortcut",
                            "due": "On arrival"
                        },
                        {
                            "description": "Assign user in asset register",
                            "due": "On issue"
                        },
                        {
                            "description": "Hardware policy signed",
                            "due": "On issue"
                        },
                    ]
                }
                temp.map((item, index) => {
                    subTasks.push({
                        description: item.description,
                        due: item.due,
                        id: `${id}-${index}`,
                        assigned: {
                            email: "",
                            id: "",
                            name: "",
                        },
                        status: "Queued"
                    })
                })
            }
            const temp: ticket = {
                raisedBy: tempRaisedBy,
                dateRaised: moment().toString(),
                firstNoticed: moment(noticedDate).toString(),
                description: description,
                status: "Queued",
                subject: summary,
                location: location,
                device: device,
                type: type,
                subTasks: subTasks,
                ticketId: id,
                priority: priority,
                comments: [],
            }
            if (file) {
                temp.file = file.name
                addFile(file)
            }
            raiseTicket(temp)
        }
    }
const mobileMedia = window.matchMedia("(max-width: 480px)").matches
    return (
        <div className="raiseTicket">
            <img className="raiseTicket__wave" src={wave} />
            {page === 1 &&
                <div className="raiseTicket-one">
                    <p className="raiseTicket__label">Ticket summary:</p>
                    <Form.Control onChange={(e) => setSummary(e.currentTarget.value)} className="form-control-large" placeholder="Enter ticket summary..." />
                    <div className="d-flex justify-content-end">
                        <a onClick={() => setPage(2)} className="raiseTicket__control">NEXT <FaArrowRightLong color="white" size={mobileMedia ? 20 : 40} /></a>
                    </div>
                </div>
            }
            {page === 2 &&
                <div className="raiseTicket-two">
                    <div className="mb-3">
                        <p className="raiseTicket__label--small">Ticket description:</p>
                        <Form.Control onChange={(e) => setDescription(e.currentTarget.value)} className="form-control-med" placeholder="Enter ticket description..." as="textarea" rows={3} />
                    </div>
                    <div className="mb-3">
                        <p className="raiseTicket__label--small">Upload an image:</p>
                        <Form.Control className="form-control-med" accept="image/*" onChange={(e) => {
                            //@ts-ignore
                            setFile((e.target as HTMLInputElement).files[0])
                        }} type="file" />
                    </div>
                    {!admins.find((e) => e.id === user.id) &&
                        <div className="d-flex flex-column mb-0">
                            <p className="raiseTicket__label--small">Ticket type:</p>
                            <Form.Select value={template} className="form-control-half w-100" onChange={(e) => { setTemplate(e.currentTarget.value) }}>
                                <option value={"Support ticket"}>Support ticket</option>
                                <option value={"Hardware request"}>Hardware request</option>
                            </Form.Select>
                        </div>
                    }
                    {admins.find((e) => e.id === user.id) &&
                        <div className="d-flex flex-row mb-3">
                            <div className="w-50">
                                <p className="raiseTicket__label--small">User:</p>
                                <Typeahead
                                    className="form-control-half form-control-half--right"
                                    placeholder='Search for user...'
                                    labelKey="displayName"
                                    onChange={(selected) => {
                                        //@ts-ignore
                                        setRaisedBy({ email: selected[0].mail, name: selected[0].displayName, id: selected[0].id })
                                    }}
                                    options={allUsers}
                                />
                            </div>
                            <div className="w-50">
                                <p className="raiseTicket__label--small">Ticket type:</p>
                                <Form.Select value={template} className="form-control-half form-control-half--left" onChange={(e) => { setTemplate(e.currentTarget.value) }}>
                                    <option value={"Support ticket"}>Support ticket</option>
                                    <option value={"New starter (Office)"}>New starter (Office)</option>
                                    <option value={"New starter (Site)"}>New starter (Site)</option>
                                    <option value={"Leaver (Office)"}>Leaver (Office)</option>
                                    <option value={"Leaver (Site)"}>Leaver (Site)</option>
                                    <option value={"Role change"}>Role change</option>
                                    <option value={"Hardware request"}>Hardware request</option>
                                </Form.Select>
                            </div>
                        </div>
                    }
                    <div className="d-flex justify-content-between">
                        <a onClick={() => setPage(1)} className="raiseTicket__control"><FaArrowLeftLong color="white" size={mobileMedia ? 20 : 40} /> BACK</a>
                        <a onClick={() => {
                            if(template === "Support ticket" || template === "Hardware request") {
                                setPage(3)
                            } else {
                                validate()
                            }
                        }} className="raiseTicket__control">{(template === "Support ticket" || template === "Hardware request") ? "NEXT" : "SUBMIT"} <FaArrowRightLong color="white" size={mobileMedia ? 20 : 40} /></a>
                    </div>
                </div>
            }
            {page === 3 &&
                <div className="raiseTicket-two">
                    <div className={mobileMedia ? "d-flex flex-column mb-0" : "d-flex flex-row mb-3"}>
                        {template === "Support ticket" ?
                            <>
                                <div className="w-50">
                                    <p className="raiseTicket__label--small">Device:</p>
                                    <Form.Select value={device} className={mobileMedia ? "form-control-med" : "form-control-half form-control-half--right"} onChange={(e) => { setDevice(e.currentTarget.value) }}>
                                        <option value={""}>Select a device...</option>
                                        <option value={"Phone"}>Phone</option>
                                        <option value={"Tablet"}>Tablet</option>
                                        <option value={"Laptop"}>Laptop</option>
                                        <option value={"ThinClient"}>ThinClient</option>
                                        <option value={"Printer"}>Printer</option>
                                        <option value={"Other"}>Other</option>
                                    </Form.Select>
                                </div>
                                <div className="w-50">
                                    <p className="raiseTicket__label--small">Type:</p>
                                    <Form.Select value={type} className={mobileMedia ? "form-control-med" : "form-control-half form-control-half--left"} onChange={(e) => { setType(e.currentTarget.value) }}>
                                        <option value={""}>Select issue type...</option>
                                        <option value={"Bluebeam"}>Bluebeam</option>
                                        <option value={"Email"}>Email</option>
                                        <option value={"ERP"}>ERP</option>
                                        <option value={"App"}>App</option>
                                        <option value={"Hardware"}>Hardware</option>
                                        <option value={"RDS"}>RDS</option>
                                        <option value={"Sharepoint"}>Sharepoint</option>
                                        <option value={"VPN/Sophos"}>VPN/Sophos</option>
                                        <option value={"3CX"}>3CX</option>
                                        <option value={"Other"}>Other</option>
                                    </Form.Select>
                                </div>
                            </>
                            :
                            <div style={{width: mobileMedia ? "90vw" : "61rem"}}>
                                <p className="raiseTicket__label--small">Device:</p>
                                <Form.Select value={device} className="form-control-half w-100" onChange={(e) => { setDevice(e.currentTarget.value) }}>
                                    <option value={""}>Select a device...</option>
                                    <option value={"Phone"}>Phone</option>
                                    <option value={"Tablet"}>Tablet</option>
                                    <option value={"Laptop"}>Laptop</option>
                                    <option value={"ThinClient"}>ThinClient</option>
                                    <option value={"Printer"}>Printer</option>
                                    <option value={"Other"}>Other</option>
                                </Form.Select>
                            </div>
                        }
                    </div>
                    {template === "Support ticket" &&
                    <>
                    <div className={mobileMedia ? "d-flex flex-column mb-0" : "d-flex flex-row mb-3"}>
                        <div className="w-50">
                            <p className="raiseTicket__label--small">Issue first noticed:</p>
                            <DatePicker onChange={(date: Date) => setNoticedDate(date)} locale="en-GB" className={mobileMedia ? "form-control-med form-control" : "form-control form-control-half form-control-half--right"} calendarClassName='w-100' selected={noticedDate} />
                        </div>
                        <div className="w-50">
                            <p className="raiseTicket__label--small">Who does this affect:</p>
                            <Form.Select onChange={(e) => setAffect(parseInt(e.currentTarget.value))} className={mobileMedia ? "form-control-med" : "form-control-half form-control-half--left"}>
                                <option value={0}>Select an option...</option>
                                <option value={1}>Just me</option>
                                <option value={2}>My department</option>
                                <option value={3}>Whole company</option>
                            </Form.Select>
                        </div>
                    </div>
                    <div className={mobileMedia ? "d-flex flex-column mb-3" : "d-flex flex-row mb-3"}>
                        <div className="w-50">
                            <p className="raiseTicket__label--small">Is this stopping you from working?</p>
                            <Form.Select onChange={(e) => setBlocked(parseInt(e.currentTarget.value))} className={mobileMedia ? "form-control-med" : "form-control-half form-control-half--right"}>
                                <option value={0}>Select an option...</option>
                                <option value={1}>No - There are other things I can be doing</option>
                                <option value={2}>Yes - I can't complete my current task</option>
                                <option value={3}>Yes - I can't work at all</option>
                            </Form.Select>
                        </div>
                        <div className="w-50">
                            <p className="raiseTicket__label--small">Where are you working from:</p>
                            <Form.Select className={mobileMedia ? "form-control-med" : "form-control-half form-control-half--left"} onChange={(e) => { setLocation(e.currentTarget.value) }}>
                                <option value={""}>Select an option...</option>
                                <option value="The office">The office</option>
                                <option value="Home">Home</option>
                                <option value="Other">Other</option>
                            </Form.Select>
                        </div>
                    </div>
                    </>
                    }
                    <div className="d-flex justify-content-between">
                        <a onClick={() => setPage(2)} className="raiseTicket__control"><FaArrowLeftLong color="white" size={40} /> BACK</a>
                        <a onClick={() => {
                            setPage(4)
                            validate()
                        }} className="raiseTicket__control">SUBMIT <FaArrowRightLong color="white" size={40} /></a>
                    </div>
                </div>
            }
            {page === 4 &&
                <div className="raiseTicket-three">
                    <FaCircleCheck size={100} />
                    <p>Your ticket has been raised</p>
                    <Button onClick={() => setPage(1)} className="buttonES">Continue</Button>
                </div>
            }
        </div>
    )
}

export default RaiseTicket