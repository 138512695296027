import { Asset, User, graphAPIData, ticket } from "../Pages/AuthenticatedHome"
import wave from "../Images/wave.png"
import { Button, Form } from "react-bootstrap"
import { FaX } from "react-icons/fa6";
import { useEffect, useState } from "react";
import { allUsers } from "../TempData/TempData";
import { Typeahead } from "react-bootstrap-typeahead";
import { Project } from "../Pages/SubPages/Developer";
import { toast } from "react-toastify";

interface Props {
    closeProject: () => void
    saveProject: (project: Project) => void
    create: boolean
    projects: Project[]
    children: React.ReactNode
}

const ProjectContainer = ({ closeProject, saveProject, children, create, projects }: Props) => {
    const [name, setName] = useState<string>("")
    const [id, setId] = useState<string>("")
    const checkState = () => {
        if (create === false) {
            return true
        }
        return false
    }

    const submit = () => {
        const check:string[] = []
        projects.map((project) => check.push(project.id))
        if(!check.includes(id)) {
            const temp: Project = {
                projectName: name,
                status: "Queued",
                id: id,
                tickets: {
                    Queued: [],
                    "In Progress": [],
                    Testing: [],
                    Completed: []
                }
            }
            saveProject(temp)
        } else {
            toast.error('Project ID already exists. Project not created.', {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        }
    }
    return (
        <div className="containerES" style={{ overflowX: "hidden" }}>
            <div className={`containerES-children ${checkState() && "w-100"}`}>
                {children}
            </div>
            {create &&
                <div className="containerES-ticket">
                    <img className="containerES-ticket__wave" src={wave} />
                    <div className="containerES-ticket-content">
                        <div className="d-flex flex-row justify-content-between align-items-center">
                            <p className="containerES-ticket-content__id">Create new project</p>
                            <FaX onClick={closeProject} className="containerES-ticket-content__close" color="white" size={24} />
                        </div>
                        <Form.Group className="mb-2">
                            <p className="containerES-ticket-content__label">Project ID:</p>
                            <Form.Control maxLength={3} value={id} onChange={(e) => setId(e.currentTarget.value)} placeholder="Enter project id..." />
                        </Form.Group>
                        <Form.Group className="mb-2">
                            <p className="containerES-ticket-content__label">Project name:</p>
                            <Form.Control value={name} onChange={(e) => setName(e.currentTarget.value)} placeholder="Enter project name..." />
                        </Form.Group>
                        <div className="d-flex justify-content-end">
                            <Button onClick={() => {
                                submit()
                                closeProject()
                            }} className="buttonES">Create project</Button>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default ProjectContainer