import { FaX } from "react-icons/fa6"
import { KnowledgebaseItem, User, graphAPIData, ticket } from "../Pages/AuthenticatedHome"
import wave from "../Images/wave.png"
import { Editor } from "react-draft-wysiwyg";
import { EditorState, RawDraftContentState, convertFromHTML, convertFromRaw, convertToRaw } from "draft-js"
import { useEffect, useState } from "react"
import { Button } from "react-bootstrap"
import draftToHtml from "draftjs-to-html";

interface Props {
    children: React.ReactNode
    ticket?: ticket
    user: graphAPIData
    allKnowledge: KnowledgebaseItem[]
    admins: User[]
    viewTicket: boolean
    closeTicket: () => void
    newKnowledge: (item: KnowledgebaseItem) => void
}

const KnowledgeContainer = ({ admins, newKnowledge, allKnowledge, children, closeTicket, user, viewTicket, ticket }: Props) => {
    const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty())
    const [populated, setPopulated] = useState<boolean>(false)
    useEffect(() => {
        setPopulated(false)
        if (ticket) {
            setEditorState(EditorState.createEmpty())
            allKnowledge.map((item) => {
                if (item.ticketId === ticket?.ticketId) {
                    setPopulated(true)
                    setEditorState(EditorState.createWithContent(convertFromRaw(JSON.parse(item.text))))
                    if(convertToRaw(editorState.getCurrentContent()).blocks[0].text === "") {
                        setPopulated(false)
                    }
                }
            })
        }
    }, [ticket])

    const createKnowledge = () => {
        if (ticket && editorState) {
            const tempUser: User = {
                email: user.mail,
                id: user.id,
                name: user.displayName
            }
            const temp: KnowledgebaseItem = {
                date: new Date().toString(),
                lastUser: tempUser,
                text: JSON.stringify(convertToRaw(editorState.getCurrentContent())),
                ticketId: ticket.ticketId
            }
            newKnowledge(temp)
            setPopulated(true)
        }
    }


    return (
        <div className="containerES">
            <div className={`containerES-children ${!viewTicket && "w-100"}`}>
                {children}
            </div>
            {viewTicket && ticket &&
                <div className="containerES-ticket">
                    <img className="containerES-ticket__wave" src={wave} />
                    <div className="containerES-ticket-content">
                        <div className="d-flex flex-row justify-content-between align-items-center">
                            <p className="containerES-ticket-content__id">#{ticket?.ticketId}</p>
                            <FaX onClick={closeTicket} className="containerES-ticket-content__close" color="white" size={24} />
                        </div>
                        <h2 className="containerES-ticket-content__title">{ticket?.subject}</h2>
                        <div className="containerES-ticket-content-row mb-0">
                            <div className="d-flex flex-column w-25 mr1">
                            <p className="containerES-ticket-content__label">Raised by:</p>
                                <p className="containerES-ticket-content__value">{ticket?.raisedBy.name}</p>
                            </div>
                            <div className="d-flex flex-column w-25 mr1">
                            <p className="containerES-ticket-content__label">Assigned:</p>
                                <p className="containerES-ticket-content__value">{ticket?.assigned?.name || ""}</p>
                            </div>
                            <div className="d-flex flex-column w-25 mr1">
                                <p className="containerES-ticket-content__label">Date raised:</p>
                                <p className="containerES-ticket-content__value">{ticket?.dateRaised}</p>
                            </div>
                            <div className="d-flex flex-column w-25">
                                <p className="containerES-ticket-content__label">First noticed:</p>
                                <p className="containerES-ticket-content__value">{ticket?.firstNoticed}</p>
                            </div>
                        </div>
                        <p className="containerES-ticket-content__label">Description:</p>
                        <div className="containerES-ticket-content-description" dangerouslySetInnerHTML={{ __html: ticket?.description || "" }}></div>
                        <p className="containerES-ticket-content__label">Ticket resolution:</p>
                        {populated ?
                            <div dangerouslySetInnerHTML={{ __html: draftToHtml(convertToRaw(editorState.getCurrentContent())) }}></div>
                            :
                            <Editor
                                wrapperStyle={{ "height": "25rem" }}
                                placeholder='Enter resolution...'
                                editorState={editorState}
                                onEditorStateChange={(e) => setEditorState(e)}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="form-control editor-height"
                                toolbar={{
                                    options: ['list', 'inline'],
                                    inline: {
                                        options: ['bold', 'italic', 'underline']
                                    },
                                    list: {
                                        options: ['unordered'],
                                    }
                                }}
                            />
                        }
                        <div className="d-flex justify-content-end mt-5">
                            {populated ?
                            <Button onClick={() => setPopulated(false)} className="buttonES">Edit changes</Button>
                            :
                            <Button onClick={() => createKnowledge()} className="buttonES">Save changes</Button>

                        }
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default KnowledgeContainer